import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/templates/MDXDocs.js";
import { Divider } from 'fannypack';
import ColouredText from '../../../components/worksafe-vic/ColouredText';
import Highlight from '../../../components/worksafe-vic/Highlight';
import NumberLabel from '../../../components/worksafe-vic/NumberLabel';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2 {...{
      "id": "tyro-health-playground",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#tyro-health-playground",
        "aria-label": "tyro health playground permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Tyro Health playground`}</h2>
    <p>{`Tyro Health has a playground to allow you to test SDK transactions. You can access Playground by  clicking on the below link:`}</p>
    <p><a parentName="p" {...{
        "href": "https://playground.medipass.io/"
      }}>{`https://playground.medipass.io/`}</a></p>
    <p>{`You will need a `}<a parentName="p" {...{
        "href": "https://help.medipass.com.au/en/articles/1142164-how-to-generate-a-medipass-api-key"
      }}><strong parentName="a">{`API Key`}</strong></a>{` and APP ID to use Playground. `}<br />{`
Once you have inserted your API Key and APP ID click on the refreash token button.`}</p>
    <h3 {...{
      "id": "sample-transactions",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#sample-transactions",
        "aria-label": "sample transactions permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Sample transactions`}</h3>
    <h4 {...{
      "id": "sample-transaction-1-eftpos-payment",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#sample-transaction-1-eftpos-payment",
        "aria-label": "sample transaction 1 eftpos payment permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Sample transaction 1: EFTPOS Payment`}</h4>
    <p><a parentName="p" {...{
        "href": "https://playground.medipass.io/?query=%7B%22platform%22%3A%22virtual-terminal%22%2C%22providerNumber%22%3A%222159081W%22%2C%22chargeAmount%22%3A%22%2445%22%2C%22paymentMethod%22%3A%22terminal%22%2C%22patient%22%3A%7B%22mobile%22%3A%220472637746%22%7D%7D&config=%7B%22env%22%3A%22stg%22%2C%22apiKey%22%3A%22%22%2C%22appId%22%3A%22%22%7D"
      }}>{`Try EFTPOS Payment via Playground`}</a></p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`medipassTransactionSDK.renderCreateTransaction({
    platform: “virtual-terminal”,
    chargeAmount: “$45”,
    paymentMethod: “terminal”,
   
    patient: {
       firstName: “Emma”,
       lastName: “Blazer”,
       dob: “1958-06-10”,
       mobile: “+61484354679”,
       refId: “66704”
    },
    
{
  onSuccess: function (transaction) { /* ... */ },
  onError: function (error) { /* ... */ },
  onCancel: function () { /* ... */ }
})

`}</code></pre>
    <h4 {...{
      "id": "sample-transactions-2-healthpoint-claim",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#sample-transactions-2-healthpoint-claim",
        "aria-label": "sample transactions 2 healthpoint claim permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Sample transactions 2: HealthPoint claim`}</h4>
    <p><a parentName="p" {...{
        "href": "https://playground.medipass.io/?query=%7B%22funder%22%3A%22phi%22%2C%22platform%22%3A%22funder%22%2C%22providerNumber%22%3A%222159081W%22%2C%22funderData%22%3A%7B%22phi%22%3A%7B%22isQuote%22%3A%22false%22%7D%7D%2C%22memberStatus%22%3A%22new%22%2C%22patient%22%3A%7B%22refId%22%3A%2266704%22%2C%22firstName%22%3A%22Emma%22%2C%22lastName%22%3A%22Blazer%22%2C%22dob%22%3A%221958-06-10%22%2C%22mobile%22%3A%22%2B61484354679%22%2C%22reference%22%3A%2201%22%7D%2C%22claimableItems%22%3A%5B%7B%22serviceDateString%22%3A%222022-01-20%22%2C%22price%22%3A%22%24201%22%7D%5D%7D&config=%7B%22env%22%3A%22stg%22%2C%22apiKey%22%3A%22%22%2C%22appId%22%3A%22%22%7D"
      }}>{`Try HealthPoint claim via Playground`}</a></p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "{",
        "{": true
      }}>{`{
    funder: “phi”,
    platform: “funder”,
    invoiceReference: “INV1234”,
    providerNumber: “0034503W”,
    patient: {
      firstName: “Emma”,
      lastName: “Blazer”,
      dob: “1958-06-10”,
      reference: “01”,
      mobile: “+61484354679”,
      refId: “66704”
    },

    funderData: {
      phi: {
         isRebateEstimate: false
           }
    },

    claimableItems: [
      {
        itemCode: “501”,
        price: “$201”,
        serviceDate: “2022-01-20”
       
      }
    ]
  }

`}</code></pre>
    <h4 {...{
      "id": "sample-transactions-3-healthpoint-rebate-estimate",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#sample-transactions-3-healthpoint-rebate-estimate",
        "aria-label": "sample transactions 3 healthpoint rebate estimate permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Sample transactions 3: HealthPoint rebate estimate`}</h4>
    <p><a parentName="p" {...{
        "href": "https://playground.medipass.io/?query=%7B%22platform%22%3A%22funder%22%2C%22funder%22%3A%22phi%22%2C%22providerNumber%22%3A%222159081W%22%2C%22funderData%22%3A%7B%22phi%22%3A%7B%22isQuote%22%3A%22true%22%7D%7D%2C%22memberStatus%22%3A%22new%22%2C%22patient%22%3A%7B%22refId%22%3A%2266704%22%2C%22firstName%22%3A%22Emma%22%2C%22lastName%22%3A%22Blazer%22%2C%22dob%22%3A%221958-06-10%22%2C%22mobile%22%3A%22%2B61484354679%22%2C%22reference%22%3A%2201%22%7D%2C%22claimableItems%22%3A%5B%7B%22serviceDateString%22%3A%222022-01-20%22%2C%22price%22%3A%22%24201%22%7D%5D%7D&config=%7B%22env%22%3A%22stg%22%2C%22apiKey%22%3A%22%22%2C%22appId%22%3A%22%22%7D"
      }}>{`Try HealthPoint rebate estimate via Playground`}</a></p>
    <pre><code parentName="pre" {...{
        "className": "language-js{"
      }}>{`
  {
    funder: “phi”,
    platform: “funder”,
    invoiceReference: “INV1234”,
    providerNumber: “0034503W”,
    patient: {
      firstName: “Emma”,
      lastName: “Blazer”,
      dob: “1958-06-10”,
      reference: “01”,
      mobile: “+61484354679”,
      refId: “66704”
    },

    funderData: {
      phi: {
         isRebateEstimate: true
           }
    },

    claimableItems: [
      {
        itemCode: “501”,
        price: “$201”,
       serviceDate: “2022-01-20”
       
      }
    ]
  }

`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      